import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { hydrate, render } from "react-dom";
import createCache from '@emotion/cache';

import './lib/styles/index.scss';
import theme from './lib/theme';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { CacheProvider } from '@emotion/react';

const rootElem = document.getElementById('root') as HTMLElement;

function createEmotionCache() {
  return createCache({ key: 'css' });
}

if (rootElem.hasChildNodes()) {
  const cache = createEmotionCache();

  hydrate(
    <React.StrictMode>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </CacheProvider>
    </React.StrictMode>,
    rootElem
  );
} else {
  render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    rootElem
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
