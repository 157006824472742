import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Theme, useMediaQuery } from '@mui/material';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './Home.scss';
import LazyShow from '../../components/LazyShow/LazyShow';
import ReportTable, { ReportReact } from '../../components/Report/ReportTable';
import PricingTable from '../../components/PricingTable/PricingTable';
import AddonsTable from '../../components/PricingTable/AddonsTable';
import { contactRoute, reportRoute } from '../../components/Browser/routes';

const Home = () => {
  const navigate = useNavigate();
  const [report, setReport] = React.useState<ReportReact | null>(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [countDown, setCountDown] = React.useState({ days: 0, hours: 0 });

  const handleSetOpen = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Get Free Audit Report'
    });

    navigate(reportRoute());
  };

  // const countDownDate = moment('28.06.2025', 'DD.MM.YYYY');
  // const current = moment();

  // const countDown = {
  //   days: countDownDate.diff(current, 'days'),
  //   hours: countDownDate.diff(current, 'hours') % 24
  // };

  useEffect(() => {
    const countDownDate = moment('28.06.2025', 'DD.MM.YYYY');
    const current = moment();

    setCountDown({
      days: countDownDate.diff(current, 'days'),
      hours: countDownDate.diff(current, 'hours') % 24
    })
  }, []);

  const getInTouch = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Get In Touch'
    });
    navigate(contactRoute());
  };

  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: 'https://accessibridge.com/',
    name: 'AccessiBridge',
    description:
      'Get a comprehensive WCAG audit report with Accessi Bridge. Ensure your website meets accessibility standards with detailed analysis, actionable insights, and clear recommendations to improve compliance and user experience. Optimize your site for all users today.',
    publisher: {
      '@type': 'Organization',
      name: 'MNB solutions',
      logo: {
        '@type': 'ImageObject',
        url: 'https://accessibridge.com/static/media/logo.2e9ef81791736ac6a81a.png'
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>AccessiBridge | WCAG Accessibility Audit Report</title>
        <link rel="canonical" href="https://accessibridge.com" />
        <meta
          name="description"
          content="Get a comprehensive WCAG audit report with Accessi Bridge. Ensure your website meets accessibility standards with detailed analysis, actionable insights, and clear recommendations to improve compliance and user experience. Optimize your site for all users today."
        />
        <meta
          name="keywords"
          content="WCAG audit report, website accessibility audit, accessibility, audit, report, WCAG, ADA, compliance, website, web, digital, accessibility, standards, analysis, insights, recommendations, improve, user, experience, optimize, users, accessi bridge"
        />
        <meta name="author" content="MNB solutions" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="AccessiBridge | Accessibility Audit Report" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://accessibridge.com" />
        <meta
          property="og:description"
          content="Get a comprehensive WCAG audit report with Accessi Bridge. Ensure your website meets accessibility standards with detailed analysis, actionable insights, and clear recommendations to improve compliance and user experience. Optimize your site for all users today."
        />
        {/*<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>*/}
      </Helmet>
      <main role="main">
        <section id={'section-1'} className={'main-bg'}>
          <Box py={7} />
          <div className={'card-body'}>
            <div className={'card-layout'}>
              <div className={'card-content'}>
                <div className={'card'}>
                  <LazyShow>
                    <Typography variant={'h1'} fontWeight={'500'} pb={2}>
                      Get Your Accessibility Audit Report
                    </Typography>
                    <Typography variant={'h2'}>Powered by Accessi Bridge</Typography>
                    <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                      Ensure your website's digital accessibility with Accessi Bridge, a powerful tool that scans
                      websites for compliance with EAA, BFSG and WCAG standards.
                    </Typography>
                    <Box pt={5}>
                      <Button variant={'contained'} color={'primary'} onClick={handleSetOpen}>
                        Get Free Audit Report
                      </Button>
                    </Box>
                  </LazyShow>
                </div>
                <div className={'card'}>
                  {/*<img src={mainImage} alt={'logo'} width={'100%'} className={'card-image'} />*/}
                  <div className={'card-image'} />
                </div>
              </div>
            </div>
          </div>
          <Box py={7} />
        </section>
        <section id={'section-result'} className={'main-bg'}>
          {report && (
            <>
              <Box py={7} />
              <Paper
                sx={(theme) => ({
                  p: 5,
                  margin: 'auto',
                  maxWidth: 1250,
                  // flexGrow: 1,
                  backgroundColor: 'transparent',
                  ...theme.applyStyles('dark', {
                    backgroundColor: '#1A2027'
                  })
                })}
                elevation={0}
              >
                <Grid container justifyContent={'space-between'} columnGap={1}>
                  <Grid item xs={12}>
                    <Typography variant={'h2'} fontWeight={'normal'} pb={2}>
                      AccessiBridge Report Overview
                    </Typography>
                  </Grid>

                  {report.results.map((result, index) => (
                    <ReportTable key={index} report={result} fullReportUrl={report.url} />
                  ))}
                </Grid>
              </Paper>
              <Box py={7} />
            </>
          )}
        </section>
        <section id={'section-2'} style={{ position: 'relative' }}>
          <div className={'parallax image-bg-1'} />
          <Box py={7} />
          <Paper
            sx={(theme) => ({
              p: 5,
              margin: 'auto',
              maxWidth: 1250,
              // flexGrow: 1,
              backgroundColor: 'transparent',
              ...theme.applyStyles('dark', {
                backgroundColor: '#1A2027'
              })
            })}
            elevation={0}
          >
            <Grid container justifyContent={'space-between'} columnGap={1}>
              <Grid item xs={12}>
                <LazyShow>
                  <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                    About Digital Accessibility
                  </Typography>
                </LazyShow>
              </Grid>

              <Grid item xs={12} sm={5.9} className={'card-bg'} p={2}>
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    Why Digital Accessibility Matters
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    Approximately 15-20% of the population has some form of disability or belongs to the senior
                    demographic. By ensuring digital accessibility, you can extend your product's reach to a broader
                    audience. Additionally, websites optimized for accessibility tend to perform better in search engine
                    rankings (SEO), increasing their visibility.
                    <br />
                    <br />
                  </Typography>
                  <Typography variant={'h5'} fontWeight={'normal'} className={'card-title'} pt={4}>
                    Other key benefits include:
                  </Typography>
                  <List
                    sx={{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item'
                      }
                    }}
                  >
                    <ListItem>
                      Improved Usability: Accessibility enhancements often lead to a more streamlined and intuitive user
                      experience for all visitors.
                    </ListItem>
                    <ListItem>
                      Positive Brand Perception: Demonstrating a commitment to inclusivity enhances your brand’s
                      reputation, fostering trust and loyalty.
                    </ListItem>
                    <ListItem>
                      Higher Engagement: Accessible websites promote greater interaction by making content easier to
                      navigate and understand for diverse user groups.
                    </ListItem>
                  </List>
                </LazyShow>
              </Grid>
              <Grid item xs={12} sm={5.9} className={'card-bg'} p={2}>
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    Understanding the EAA and WCAG
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    The European Accessibility Act (EAA) is a directive that aims to improve accessibility across the
                    EU, ensuring that products and services, including websites, are accessible to people with
                    disabilities. It complements the Web Content Accessibility Guidelines (WCAG), which provide specific
                    recommendations to make web content perceivable, operable, understandable, and robust.
                  </Typography>
                  <Typography variant={'h5'} fontWeight={'normal'} className={'card-title'} pt={4}>
                    Examples of Digital Accessibility
                  </Typography>
                  <List
                    sx={{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item'
                      }
                    }}
                    role={'list'}
                  >
                    <ListItem>
                      Perceivable: Adding alt text to images helps visually impaired users understand the content
                      through screen readers.
                    </ListItem>
                    <ListItem>
                      Operable: Keyboard navigation ensures that people who cannot use a mouse can still browse the
                      site.
                    </ListItem>
                    <ListItem>
                      Understandable: Simple error messages guide users to correct mistakes without confusion.
                    </ListItem>
                    <ListItem>
                      Robust: Websites are built to work with various devices and assistive technologies, ensuring
                      accessibility for all.
                    </ListItem>
                  </List>
                </LazyShow>
              </Grid>
            </Grid>
          </Paper>
          <Box py={7} />
        </section>
        <section id={'section-3'} className={'main-bg'}>
          {/*<div className={'image-bg-2'} />*/}
          <Box py={7} />
          <Paper
            sx={(theme) => ({
              p: 5,
              margin: 'auto',
              maxWidth: 1250,
              // flexGrow: 1,
              backgroundColor: 'transparent',
              ...theme.applyStyles('dark', {
                backgroundColor: '#1A2027'
              })
            })}
            elevation={0}
          >
            <Grid container justifyContent={'space-between'} columnGap={1}>
              <Grid item xs={12}>
                <LazyShow>
                  <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                    The European Accessibility Act (EAA)
                  </Typography>
                </LazyShow>
              </Grid>

              {!isMobile && (
                <Grid item xs={12} py={10}>
                  <div className="timeline-container">
                    <div className="timeline">
                      <LazyShow>
                        <div className="marker" style={{ left: '25%' }}>
                          <div className="label">1</div>
                        </div>
                      </LazyShow>
                      <LazyShow>
                        <div className="marker" style={{ left: '75%' }}>
                          <div className="label">2</div>
                        </div>
                      </LazyShow>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={5.9} p={2}>
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    European Accessibility Act Countdown
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    The European Accessibility Act (EAA), adopted in 2019, is a directive aimed at harmonizing
                    accessibility standards across the EU. Member states are currently finalizing the process of
                    implementing it, and businesses must ensure compliance by <b>June 28, 2025</b>. The act sets out
                    clear requirements for making digital products and services accessible, removing barriers for people
                    with disabilities.
                  </Typography>
                </LazyShow>
              </Grid>
              <Grid item xs={12} sm={5.9} p={2}>
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    {countDown.days} days and {countDown.hours} hours until EAA takes effect
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    With the compliance deadline fast approaching in 2025, businesses should act now to align with the
                    EAA to avoid fines that can reach up to €100,000 for each violation. Taking proactive steps to meet
                    accessibility standards not only helps avoid legal issues but also ensures inclusivity for all
                    users, enhancing customer trust and expanding your market reach.
                  </Typography>
                </LazyShow>
              </Grid>
            </Grid>
          </Paper>
          <Box py={7} />
        </section>
        <section id={'section-4'} style={{ position: 'relative' }}>
          <div className={'parallax image-bg-2'} />
          <Box py={7} />
          <Paper
            sx={(theme) => ({
              p: 5,
              margin: 'auto',
              maxWidth: 1250,
              // flexGrow: 1,
              backgroundColor: 'transparent',
              ...theme.applyStyles('dark', {
                backgroundColor: '#1A2027'
              })
            })}
            elevation={0}
          >
            {/* TODO: prerobit do tabulky */}
            <Grid container justifyContent={'space-between'} columnGap={1}>
              <Grid item xs={12}>
                <LazyShow>
                  <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                    Our 3-Step Plan To Achieve EAA/BFSG Compliance
                  </Typography>
                </LazyShow>
              </Grid>

              {!isMobile && (
                <Grid item xs={12} py={2}>
                  <LazyShow>
                    <div className="chevron-container">
                      <div className="chevron">
                        <span>1</span>
                      </div>
                      <div className="chevron">
                        <span>2</span>
                      </div>
                      <div className="chevron">
                        <span>3</span>
                      </div>
                    </div>
                  </LazyShow>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={3.9}
                p={2}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    Compliance Assessment
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    We start with a thorough assessment of your current accessibility status using the{' '}
                    <b>AccessiBridge Overview Report</b>, <b>Remediation Blueprint</b>, and <b>Expert Audit</b>. The{' '}
                    <b>Overview Report</b>, designed for stakeholders, provides a clear and easy-to-read summary,
                    identifying up to 50% of accessibility issues by severity. For more detailed insights, the{' '}
                    <b>Remediation Blueprint</b> offers a technical breakdown of the issues, providing developers and
                    content managers with precise instructions on what needs fixing. The
                    <b>Expert Audit</b> ensures up to 100% detection of all accessibility issues, thoroughly
                    categorizing them and setting the foundation for full compliance. While these assessments are
                    crucial, full compliance is only achieved after the necessary remediations are completed, which
                    follows in the next step.
                  </Typography>
                </LazyShow>
                <LazyShow>
                  <Box pt={2} display={'flex'}>
                    <Button variant={'contained'} color={'primary'} onClick={handleSetOpen}>
                      Start Your Compliance Assessment
                    </Button>
                  </Box>
                </LazyShow>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3.9}
                p={2}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    Achieve Compliance
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    This step focuses on implementing the solutions identified in the assessment phase. As a software
                    company, we specialize in the <b>full implementation of remediation projects</b>. Through our{' '}
                    <b>Remediation Implementation & Support</b> service, we handle the technical aspects to ensure your
                    website meets the highest accessibility standards, including <b>EAA, BFSG, and WCAG</b>. Whether you
                    need full project management, hands-on <b>implementation</b>, <b>training</b>, or a{' '}
                    <b>consultation with our experts</b>, we ensure that all identified accessibility issues are
                    resolved, leading to full compliance.
                  </Typography>
                </LazyShow>
                <LazyShow>
                  <Box pt={2} display={'flex'}>
                    <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                      Request Remediation Support
                    </Button>
                  </Box>
                </LazyShow>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3.9}
                p={2}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    Remain Compliant
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    To maintain ongoing compliance, we deploy the <b>AccessiBridge Continuity</b> solution. This
                    includes continuous site monitoring, automated functionality tests, and real-time updates via our
                    user-friendly dashboard. This solution ensures your website remains compliant with{' '}
                    <b>EAA, BFSG, and WCAG</b> standards, adapting to any new regulations or site changes over time.
                  </Typography>
                </LazyShow>
                <LazyShow>
                  <Box pt={2} display={'flex'}>
                    <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                      Get in touch
                    </Button>
                  </Box>
                </LazyShow>
              </Grid>
            </Grid>
          </Paper>
          <Box py={7} />
        </section>
        <section id={'section-6'} className={'main-bg'}>
          <Box py={7} />
          <Paper
            sx={(theme) => ({
              p: 5,
              margin: 'auto',
              maxWidth: 1250,
              backgroundColor: 'transparent',
              ...theme.applyStyles('dark', {
                backgroundColor: '#1A2027'
              })
            })}
            elevation={0}
          >
            {/* TODO: prerobit do tabulky */}
            <Grid container justifyContent={'space-between'} columnGap={1}>
              <Grid item xs={12}>
                <LazyShow>
                  <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                    Our 3-Step Audit Process
                  </Typography>
                </LazyShow>
              </Grid>

              {!isMobile && (
                <Grid item xs={12} py={2}>
                  <LazyShow>
                    <div className="chevron-container">
                      <div className="chevron">
                        <span>1</span>
                      </div>
                      <div className="chevron">
                        <span>2</span>
                      </div>
                      <div className="chevron">
                        <span>3</span>
                      </div>
                    </div>
                  </LazyShow>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={3.9}
                p={2}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    AccessiBridge Overview Report
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    Our initial scan covers all sites under one domain, categorizing up to 50% of digital accessibility
                    issues by severity. This high-level, easy-to-read report is designed to help stakeholders quickly
                    understand key areas for improvement and take action. For example, it might highlight that "5 images
                    on the home page do not meet the necessary contrast criteria."
                  </Typography>
                </LazyShow>
                <LazyShow>
                  <Box pt={2} display={'flex'}>
                    <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                      <a
                        href={
                          'https://reports.accessibridge.com/sample/audit_overview_contact_form_high_level_only.html'
                        }
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        SAMPLE OVERVIEW REPORT
                      </a>
                    </Typography>
                  </Box>
                </LazyShow>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3.9}
                p={2}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    AccessiBridge Remediation Blueprint
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    Building on the Overview Report, this detailed and technical report provides in-depth insights for
                    developers and content managers. It identifies specific areas that need fixing, such as pinpointing
                    where code needs to be amended to meet compliance requirements. With expert consultation included,
                    this report helps ensure your team can confidently tackle remediation tasks.
                  </Typography>
                </LazyShow>
                <LazyShow>
                  <Box pt={2}>
                    <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                      <a
                        href={
                          'https://reports.accessibridge.com/sample/audit_overview_contact_form_no_guided_tests.html'
                        }
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        SAMPLE REMEDIATION BLUEPRINT
                      </a>
                    </Typography>
                  </Box>
                </LazyShow>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3.9}
                p={2}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <LazyShow>
                  <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                    AccessiBridge Expert Audit
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    This final step involves a comprehensive review led by our accessibility experts. The{' '}
                    <b>Expert Audit</b> ensures that all issues identified in the previous stages are thoroughly
                    assessed, and that your site is on track to meet the highest accessibility standards, including{' '}
                    <b>WCAG, EAA, and BFSG</b>. However, <b>compliance is not fully achieved with the audit alone</b>
                    —remediation must be completed to resolve the issues. We are happy to support you with our{' '}
                    <b>Remediation Implementation & Support</b> plan to ensure your website meets full compliance and
                    gives you peace of mind.
                  </Typography>
                </LazyShow>
                <LazyShow>
                  <Box pt={2} display={'flex'}>
                    <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                      <a
                        href={
                          'https://reports.accessibridge.com/sample/audit_overview_contact_form_with_guided_tests.html'
                        }
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        SAMPLE EXPERT AUDIT
                      </a>
                    </Typography>
                  </Box>
                </LazyShow>
              </Grid>
            </Grid>
          </Paper>
          <Box py={7} />
        </section>
        <section id={'section-7'} style={{ position: 'relative' }}>
          <div className={'parallax image-bg-3'} />
          <Box py={7} />
          <Paper
            sx={(theme) => ({
              p: 5,
              margin: 'auto',
              maxWidth: 1280,
              backgroundColor: 'transparent',
              ...theme.applyStyles('dark', {
                backgroundColor: '#0C0A33'
              })
            })}
            elevation={0}
          >
            <Grid container justifyContent={'space-between'} columnGap={1}>
              <Grid item xs={12}>
                <LazyShow>
                  <Typography variant={'h2'} fontWeight={'normal'} pb={2}>
                    AccessiBridge Solutions
                  </Typography>
                </LazyShow>
              </Grid>

              <Grid item xs={12} py={2}>
                <LazyShow>
                  <PricingTable />
                </LazyShow>
              </Grid>
            </Grid>

            <Box py={5} />

            <Grid container justifyContent={'space-between'} columnGap={1}>
              <Grid item xs={12}>
                <LazyShow>
                  <Typography variant={'h2'} fontWeight={'normal'} pb={2}>
                    Optional Add-ons
                  </Typography>
                </LazyShow>
              </Grid>

              <Grid item xs={12} py={2}>
                <LazyShow>
                  <AddonsTable />
                </LazyShow>
              </Grid>
            </Grid>
          </Paper>
          <Box py={7} />
        </section>
        <section id={'section-9'} className={'main-bg'}>
          <Box py={7} />
          <Paper
            sx={(theme) => ({
              p: 5,
              margin: 'auto',
              maxWidth: 1250,
              // flexGrow: 1,
              backgroundColor: 'transparent',
              ...theme.applyStyles('dark', {
                backgroundColor: '#1A2027'
              })
            })}
            elevation={0}
          >
            <Grid container justifyContent={'center'} columnGap={1}>
              <LazyShow>
                <Grid item xs={12}>
                  <Typography variant={'h2'} fontWeight={'bold'} textAlign={'center'}>
                    Do you have any questions?
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={6} display={'flex'} justifyContent={'center'}>
                  <Button variant={'contained'} color={'primary'} onClick={getInTouch}>
                    Get in touch
                  </Button>
                </Grid>
              </LazyShow>
            </Grid>
          </Paper>
          <Box py={7} />
        </section>
      </main>
    </>
  );
};

export default Home;
